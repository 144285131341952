import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'border-corner-ios',
  'IconBorderCornerIos',
  [
    [
      'path',
      {
        d: 'M4 20c0 -6.559 0 -9.838 1.628 -12.162a9 9 0 0 1 2.21 -2.21c2.324 -1.628 5.602 -1.628 12.162 -1.628',
        key: 'svg-0',
      },
    ],
  ],
);
