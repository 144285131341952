import createReactComponent from '../createReactComponent';
export default createReactComponent('math-max', 'IconMathMax', [
  ['path', { d: 'M5 6a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M3 15s.616 -5.544 2.332 -7.93', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8.637 7.112c2.717 3.313 5.882 12.888 8.363 12.888c2 0 3.333 -3 4 -9',
      key: 'svg-2',
    },
  ],
]);
