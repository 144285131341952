import createReactComponent from '../createReactComponent';
export default createReactComponent('math-max-min', 'IconMathMaxMin', [
  ['path', { d: 'M15 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M3 14s.605 -5.44 2.284 -7.862m3.395 .026c2.137 2.652 4.547 9.113 6.68 11.719',
      key: 'svg-2',
    },
  ],
  [
    'path',
    { d: 'M18.748 18.038c.702 -.88 1.452 -3.56 2.252 -8.038', key: 'svg-3' },
  ],
]);
