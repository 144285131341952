import createReactComponent from '../createReactComponent';
export default createReactComponent('math-min', 'IconMathMin', [
  ['path', { d: 'M15 18a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  [
    'path',
    { d: 'M3 13s1 -9 4 -9c2.48 0 5.643 9.565 8.36 12.883', key: 'svg-1' },
  ],
  [
    'path',
    { d: 'M18.748 17.038c.702 -.88 1.452 -3.56 2.252 -8.038', key: 'svg-2' },
  ],
]);
