import createReactComponent from '../createReactComponent';
export default createReactComponent('temperature-sun', 'IconTemperatureSun', [
  [
    'path',
    { d: 'M4 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 1 0 -4 0v8.5', key: 'svg-0' },
  ],
  ['path', { d: 'M4 9h4', key: 'svg-1' }],
  [
    'path',
    { d: 'M13 16a4 4 0 1 0 0 -8a4.07 4.07 0 0 0 -1 .124', key: 'svg-2' },
  ],
  ['path', { d: 'M13 3v1', key: 'svg-3' }],
  ['path', { d: 'M21 12h1', key: 'svg-4' }],
  ['path', { d: 'M13 20v1', key: 'svg-5' }],
  ['path', { d: 'M19.4 5.6l-.7 .7', key: 'svg-6' }],
  ['path', { d: 'M18.7 17.7l.7 .7', key: 'svg-7' }],
]);
