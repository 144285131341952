import createReactComponent from '../createReactComponent';
export default createReactComponent('brightness-auto', 'IconBrightnessAuto', [
  [
    'path',
    {
      d: 'M6 6h3.5l2.5 -2.5l2.5 2.5h3.5v3.5l2.5 2.5l-2.5 2.5v3.5h-3.5l-2.5 2.5l-2.5 -2.5h-3.5v-3.5l-2.5 -2.5l2.5 -2.5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 14.5v-3.5a2 2 0 1 1 4 0v3.5', key: 'svg-1' }],
  ['path', { d: 'M10 13h4', key: 'svg-2' }],
]);
