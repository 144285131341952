import createReactComponent from '../createReactComponent';
export default createReactComponent('image-in-picture', 'IconImageInPicture', [
  ['path', { d: 'M13 15c-2 0 -5 1 -5 5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4 11m0 2a2 2 0 0 1 2 -2h5a2 2 0 0 1 2 2v5a2 2 0 0 1 -2 2h-5a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M4 7v-2a1 1 0 0 1 1 -1h2', key: 'svg-2' }],
  ['path', { d: 'M11 4h2', key: 'svg-3' }],
  ['path', { d: 'M17 4h2a1 1 0 0 1 1 1v2', key: 'svg-4' }],
  ['path', { d: 'M20 11v2', key: 'svg-5' }],
  ['path', { d: 'M20 17v2a1 1 0 0 1 -1 1h-2', key: 'svg-6' }],
]);
