import createReactComponent from '../createReactComponent';
export default createReactComponent('glass-gin', 'IconGlassGin', [
  ['path', { d: 'M8 21h8', key: 'svg-0' }],
  ['path', { d: 'M12 15v6', key: 'svg-1' }],
  [
    'path',
    { d: 'M12 5m-6.5 0a6.5 2 0 1 0 13 0a6.5 2 0 1 0 -13 0', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M5.75 4.5c-.612 .75 -.75 2 -.75 3.5a7 7 0 0 0 14 0c0 -1.5 -.094 -2.75 -.75 -3.5',
      key: 'svg-3',
    },
  ],
]);
