import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'transform-point-bottom-left',
  'IconTransformPointBottomLeft',
  [
    [
      'path',
      {
        d: 'M3 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M3 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        fill: 'currentColor',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M17 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        key: 'svg-2',
      },
    ],
    [
      'path',
      {
        d: 'M17 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M11 5h2', key: 'svg-4' }],
    ['path', { d: 'M5 11v2', key: 'svg-5' }],
    ['path', { d: 'M19 11v2', key: 'svg-6' }],
    ['path', { d: 'M11 19h2', key: 'svg-7' }],
  ],
);
