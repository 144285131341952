import createReactComponent from '../createReactComponent';
export default createReactComponent('glass', 'IconGlass', [
  ['path', { d: 'M8 21h8', key: 'svg-0' }],
  ['path', { d: 'M12 16v5', key: 'svg-1' }],
  [
    'path',
    { d: 'M17 5l1 6c0 3.012 -2.686 5 -6 5s-6 -1.988 -6 -5l1 -6', key: 'svg-2' },
  ],
  ['path', { d: 'M12 5m-5 0a5 2 0 1 0 10 0a5 2 0 1 0 -10 0', key: 'svg-3' }],
]);
