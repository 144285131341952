import createReactComponent from '../createReactComponent';
export default createReactComponent('glass-cocktail', 'IconGlassCocktail', [
  ['path', { d: 'M8 21h8', key: 'svg-0' }],
  ['path', { d: 'M12 15v6', key: 'svg-1' }],
  ['path', { d: 'M12 5m-7 0a7 2 0 1 0 14 0a7 2 0 1 0 -14 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M5 5v.388c0 .432 .126 .853 .362 1.206l5 7.509c.633 .951 1.88 1.183 2.785 .517c.191 -.141 .358 -.316 .491 -.517l5 -7.509c.236 -.353 .362 -.774 .362 -1.206v-.388',
      key: 'svg-3',
    },
  ],
]);
