import createReactComponent from '../createReactComponent';
export default createReactComponent('database-smile', 'IconDatabaseSmile', [
  ['path', { d: 'M10 14h.01', key: 'svg-0' }],
  ['path', { d: 'M14 14h.01', key: 'svg-1' }],
  ['path', { d: 'M10 17a3.5 3.5 0 0 0 4 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-3',
    },
  ],
  [
    'path',
    { d: 'M4 6v12c0 1.657 3.582 3 8 3s8 -1.343 8 -3v-12', key: 'svg-4' },
  ],
]);
