import createReactComponent from '../createReactComponent';
export default createReactComponent('clipboard-smile', 'IconClipboardSmile', [
  ['path', { d: 'M10 13h.01', key: 'svg-0' }],
  ['path', { d: 'M14 13h.01', key: 'svg-1' }],
  ['path', { d: 'M10 16a3.5 3.5 0 0 0 4 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M9 5a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-4',
    },
  ],
]);
